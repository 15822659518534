import { io } from 'socket.io-client';
import { CHAT_URL } from '../../configs/constants';

const socket = null;

// eslint-disable-next-line camelcase
const initSocket = ({ userId, user_name }) => {
	// if (socket) return socket;
	// socket = io(CHAT_URL, {
	// 	query: {
	// 		userId,
	// 		type: 'supplier',
	// 		userName: user_name,
	// 		userPhoto:
    //         'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
	// 	},
	// 	reconnection: true,
	// 	autoConnect: true,
	// 	reconnectionDelay: 500,
	// 	reconnectionAttempts: Infinity,
	// });
	// socket.on('connect', (data) => {
	// 	// });
	// 	// customer  ///customer_general  ///customer_agent->> selected by default
	// 	// Merchant  /// Merchant_Agent
	// 	// Driver /// orders  ->> selected by default /// couriers
	// 	// join room
	// });
	// return socket;
};
const getSocket = () => socket;

const disconnect = () => {
	socket.disconnect();
};
export { initSocket, getSocket, disconnect };
